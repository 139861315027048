.navbar {
    -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
    border: 1px solid #ff8100 !important;
    border-top: 2px solid #fff !important;
    background-color: #ff8100 !important;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
    color: #fff;
  }
  .nav-link{
    color: #fff;
  }
  .bg-white{
    background-color:#fff;
  }
  .float-right{
    float: right !important;
  }
  .header-bg {
  /* background-image: url(https://online.srjbtkshetra.org/headerBg.595ced377e06a2d21242.png); */
  /* height: 127px; */
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  text-align: center;
}
.logo-head {
  /* margin-top: -2%; */
  
}