.headerLine {
  -webkit-background-size: 100% 6px;
  background-image: url(https://online.srjbtkshetra.org/line-bg.156db6ac9eb12e1a9368.png);
  background-repeat: no-repeat;
  background-size: 100% 6px;
  background-position: 0 122px;
}
.header-bg {
  /* background-image: url(https://online.srjbtkshetra.org/headerBg.595ced377e06a2d21242.png); */
  /* height: 127px; */
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  text-align: center;
}
.logo-head {
  /* margin-top: -2%; */
  
}
.verifyBox{
    -webkit-border-radius: 9px;
    border-radius: 9px;
    -webkit-box-shadow: -5px 4px 8px 3px rgba(158,158,158,.33);
    box-shadow: -5px 4px 8px 3px rgba(158,158,158,.33);
    height: auto;
    padding-bottom: 34px!important;
    background-color: #fff;
    margin-top: 65px;
    margin-bottom: 12px;
    /* margin-left: 26%; */
}
.donation{
    /* -webkit-border-radius: 9px;
    border-radius: 9px;
    -webkit-box-shadow: -5px 4px 8px 3px rgba(158,158,158,.33);
    box-shadow: -5px 4px 8px 3px rgba(158,158,158,.33);
    height: auto;
    padding-bottom: 34px!important; */
    /* background-color: #fff; */
    margin: 20px auto !important;
    width: 90%;
}

.align-items-center {
    -ms-flex-align: center!important;
    -webkit-box-align: center!important;
    -webkit-align-items: center!important;
    align-items: center!important;
}
.headingver{
    padding-top: 16px;
    height: 26px;
    text-align: center;
    font-family: Helvetica;
    font-size: 18px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0;
    color: #ff8100;
}
.boxCont{
    margin-top: 34px;
    padding-left: 20px;
    padding-right: 20px;
}
.prefix {
    border: 1px solid #ced4da;
    -webkit-border-radius: 0.25rem;
    border-radius: 0.25rem;
    height: 34px;
    font-weight: 700;
    margin-top: 4px;
    background-clip: padding-box;
    background: #ced4da;
    min-width: 50px;
    width: 10%;
}
.mob-re{
    max-width: 97%;
    margin-left: 8px;
}
.otp-mob {
    margin-top: 8px;
    font-size: 12px;
    width: 100%;
    margin-left: 10px;
}
.btn-cont {
    color: #fff;
    height: 37px;
    margin-top: 17px;
    padding: 5px 30px 11px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background-color: #ff8100;
    border: none;
    width: 95%;
    margin-left: 10px;
}
.btn-cont:hover{
    background-color: #ff8100;
}
.btn-default {
    color: #fff;
    height: 37px;
    margin-top: 17px;
    padding: 5px 30px 11px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background-color: #aca4a4;
}
.btn-default:hover{
    background-color: #b1aeae;
}
.tabs{
    border-bottom: 1px solid #ff8100;
    padding-bottom: 5px;
}
.bottom-border-thik{
    border-bottom: 4px solid #ff8100;
    padding: 20px 0px 20px 0px;
}
.m-t-20{
    margin-top: 20px !important;
}
